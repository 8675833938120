import styled from 'styled-components'
import * as React from 'react'
import Layout from '../old/templates/blog/layout'
import Css from '../old/templates/blog/css'

const Date = styled.div`
  font-style: italic;
`
export default function Blog() {
  return (
    <>
      <style>{Css}</style>
      <Layout>
        <h1>Blog</h1>
        <content>
          <p>
            Nel 2006 ho aperto un <span class="selected">blog</span> personale,
            e cominciato a frequentare barcamp e blogger, soprattutto
            nell'ambito evolutosi come web 2.0 -&gt; social media -&gt;
            startup.<br />Divertente e istruttivo per un paio d'anni, poi il progetto
            ha fatto il suo tempo, come anche parallelamente lo strumento del
            blog come veicolo espressivo.
          </p>
          <p>Non c'è molto da leggere. Se vuoi posso consigliarti qualcosa.</p>
          <p>
            Su blogosfera e affini potrebbe interessarti il mio 
            <a
              title="Chi non parla con me, pesto lo colga!"
              href="chi-non-parla-con-me-pesto-lo-colga/"
            >
              resoconto dello Zenacamp 2007
            </a>
             o dell'iniziativa 
            <a
              title="RitaliaCamp, tra il dire e il fare…"
              href="ritaliacamp-tra-il-dire-e-il-fare/"
            >
              Ritalia
            </a>
            , o magari una{' '}
            <a title="Nettuno mi può giudicare" href="nettuno-mi-pu-giudicare/">
              punzecchiatina
            </a>{' '}
            alle categorizzazioni dei blog operate dagli aggregatori. Su
            editoria e giornalismo puoi vedere uno dei miei{' '}
            <a
              title="La sintonizzazione a punti"
              href="la-sintonizzazione-a-punti/"
            >
              boicottaggi demenziali
            </a>
            , un più classico 
            <a title="2 + 2 = 12.000.000" href="2-2-12000000/">
              fact checking
            </a>
            , un{' '}
            <a
              title="La mappa delle forme errate"
              href="la-mappa-delle-forme-errate/"
            >
              guizzo polemico-linguistico
            </a>
             o una disamina della{' '}
            <a
              title="I “curautori” nell’epoca della loro definibilità tecnica"
              href="i-curautori-nellepoca-della-loro-definibilit-tecnica/"
            >
              curautorialità
            </a>
            . C'è perfino una{' '}
            <a title="Le superfici basse" href="le-superfici-basse/">
              recensione di prodotto
            </a>
            .
          </p>
          <p>
            Alcuni hanno dichiarato di essere disposti a votarmi dopo una mia 
            <a title="Perché non sono qui" href="perch-non-sono-qui/">
              analisi delle ragioni di invotabilità della sinistra
            </a>
            . Ecco anche un{' '}
            <a
              title="Il giorno in cui nacque l’universo"
              href="il-giorno-in-cui-nacque-luniverso/"
            >
              cauto commento all'ascesa di Obama
            </a>
            , e un mio vecchio 
            <a
              title="Per un Freedom of Information Act italiano"
              href="per-un-freedom-of-information-act-italiano/"
            >
              invito all'adozione del Freedom of Information Act
            </a>
            . Insopportabili le frequenti{' '}
            <a
              title="Il teorema del triangolo nero"
              href="il-teorema-del-triangolo-nero/"
            >
              intemerate
            </a>{' '}
            di natura politica.
          </p>
          <p>
            Di tecnico potresti leggere un'
            <a title="Exit MIX" href="exit-mix/">
              analisi delle Start Page
            </a>{' '}
            o il resoconto del primo{' '}
            <a title="Vista-a-vista" href="vista-a-vista/">
              incontro di Microsoft Italia con i blogger
            </a>
            .
          </p>
          <p>
            Tra i racconti di cose mie spiccano i report di una{' '}
            <a title="Estatistiche" href="estatistiche/">
              vacanza spagnola
            </a>
             e di una{' '}
            <a
              title="Circoscrizione obbligatoria"
              href="circoscrizione-obbligatoria/"
            >
              capatina in circoscrizione
            </a>
            .
          </p>
          <p>
            Ogni tanto qualche giochetto oulipiano, come una 
            <a
              title="In assenza di definizione"
              href="in-assenza-di-definizione/"
            >
              definizione di Blog lipogrammata in B, L, O e G
            </a>
            , o una 
            <a
              title="Sintesi veltroniana: una lettura oulipiana di sinistra"
              href="sintesi-veltroniana-una-lettura-oulipiana-di-sinistra/"
            >
              lettura oulipiana di sinistra di un discorso veltroniano
            </a>
            , oppure invenzioni retoriche come i{' '}
            <a title="Cerchiobottèmi" href="cerchiobottmi/">
              cerchiobottemi
            </a>
             o il pastiche di 
            <a title="Belli chao" href="belli-chao/">
              Belli Chao
            </a>
            .
          </p>
          <p>Ecco comunque l'elenco completo dei post:</p>

          <ul class="posts-list">
            <li>
              <span class="date">22 Febbraio 2013</span>
              <span class="title">
                <a href="/blog/dovendo-votare/">
                  Dovendo votare
                </a>
              </span>
            </li>
            <li>
              <span class="date">22 Marzo 2012</span>
              <span class="title">
                <a href="/blog/general-diciotting/">
                  General diciotting
                </a>
              </span>
            </li>
            <li>
              <span class="date">8 Giugno 2011</span>
              <span class="title">
                <a href="/blog/sissi-sissi/">
                  Sissi s’issi!
                </a>
              </span>
            </li>
            <li>
              <span class="date">17 Maggio 2011</span>
              <span class="title">
                <a href="/blog/nonostante-il-pd/">
                  Nonostante il PD
                </a>
              </span>
            </li>
            <li>
              <span class="date">14 Aprile 2011</span>
              <span class="title">
                <a href="/blog/il-barone-lampante/">
                  Il barone lampante
                </a>
              </span>
            </li>
            <li>
              <span class="date">10 Novembre 2010</span>
              <span class="title">
                <a href="/blog/come-un-modello-per-mettere-trionfalmente-le-mani-avanti/">
                  Come un modello per mettere trionfalmente le mani avanti
                </a>
              </span>
            </li>
            <li>
              <span class="date">7 Marzo 2010</span>
              <span class="title">
                <a href="/blog/intimidatio-isterica-2-0/">
                  Intimidatio isterica 2.0
                </a>
              </span>
            </li>
            <li>
              <span class="date">6 Marzo 2010</span>
              <span class="title">
                <a href="/blog/domanda-agli-storici-quando-gia-siamo-entrati-in-fase-di-commento/">
                  Domanda agli storici quando già siamo entrati in fase di
                  commento
                </a>
              </span>
            </li>
            <li>
              <span class="date">10 Febbraio 2010</span>
              <span class="title">
                <a href="/blog/pd-pds-cds/">
                  PD, PDS, CDS
                </a>
              </span>
            </li>
            <li>
              <span class="date">16 Dicembre 2009</span>
              <span class="title">
                <a href="/blog/comitato-di-lottizzazione-nazionale/">
                  Comitato di Lottizzazione Nazionale
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Novembre 2009</span>
              <span class="title">
                <a href="/blog/destyling/">Destyling</a>
              </span>
            </li>
            <li>
              <span class="date">1 Novembre 2009</span>
              <span class="title">
                <a href="/blog/scherzetto-bello-quando-dura-poco/">
                  Scherzetto è bello quando dura poco
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/lo-stato-dello-stato/">
                  Lo stato dello Stato
                </a>
              </span>
            </li>
            <li>
              <span class="date">24 Ottobre 2009</span>
              <span class="title">
                <a href="/blog/i-like-gazebo/">
                  I like gazebo
                </a>
              </span>
            </li>
            <li>
              <span class="date">7 Agosto 2009</span>
              <span class="title">
                <a href="/blog/prime-monetine/">
                  Prime monetine
                </a>
              </span>
            </li>
            <li>
              <span class="date">2 Agosto 2009</span>
              <span class="title">
                <a href="/blog/appunti-di-combinatoria-grillesca/">
                  Appunti di combinatoria grillesca
                </a>
              </span>
            </li>
            <li>
              <span class="date">22 Febbraio 2009</span>
              <span class="title">
                <a href="/blog/una-stagione-di-unit/">
                  Una stagione di unità
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Febbraio 2009</span>
              <span class="title">
                <a href="/blog/deriva-sudamericana/">
                  Deriva sudamericana
                </a>
              </span>
            </li>
            <li>
              <span class="date">2 Gennaio 2009</span>
              <span class="title">
                <a href="/blog/il-valzer-degli-add/">
                  Il valzer degli Add
                </a>
              </span>
            </li>
            <li>
              <span class="date">26 Novembre 2008</span>
              <span class="title">
                <a href="/blog/il-pane-nellepoca-della-sua-irriproducibilit-tecnica/">
                  Il pane nell’epoca della sua irriproducibilità tecnica
                </a>
              </span>
            </li>
            <li>
              <span class="date">25 Novembre 2008</span>
              <span class="title">
                <a href="/blog/dove-sono-gli-sviluppatori-php/">
                  Dove sono gli sviluppatori PHP?
                </a>
              </span>
            </li>
            <li>
              <span class="date">4 Novembre 2008</span>
              <span class="title">
                <a href="/blog/erection-night/">
                  Erection night
                </a>
              </span>
            </li>
            <li>
              <span class="date">22 Ottobre 2008</span>
              <span class="title">
                <a href="/blog/oniricamp/">Oniricamp</a>
              </span>
            </li>
            <li>
              <span class="date">7 Ottobre 2008</span>
              <span class="title">
                <a href="/blog/dallaria-vagamente-socialista/">
                  Dall’aria vagamente socialista
                </a>
              </span>
            </li>
            <li>
              <span class="date">3 Ottobre 2008</span>
              <span class="title">
                <a href="/blog/ammortizzatori-asociali/">
                  Ammortizzatori asociali
                </a>
              </span>
            </li>
            <li>
              <span class="date">1 Ottobre 2008</span>
              <span class="title">
                <a href="/blog/volare-sulle-carcasse-degli-avvoltoi/">
                  Volare sulle carcasse degli avvoltoi
                </a>
              </span>
            </li>
            <li>
              <span class="date">12 Settembre 2008</span>
              <span class="title">
                <a href="/blog/call-center-left/">
                  Call center-left
                </a>
              </span>
            </li>
            <li>
              <span class="date">1 Settembre 2008</span>
              <span class="title">
                <a href="/blog/concitabolo/">
                  Concitabolo
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/big-burp/">Big Burp</a>
              </span>
            </li>
            <li>
              <span class="date">26 Agosto 2008</span>
              <span class="title">
                <a href="/blog/antologia-zoro/">
                  Antologia Zoro
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/o-capitale-mio-capitale/">
                  O Capitale, mio Capitale!
                </a>
              </span>
            </li>
            <li>
              <span class="date">8 Agosto 2008</span>
              <span class="title">
                <a href="/blog/tim-non-mi-accetta-come-cliente/">
                  TIM non mi accetta come cliente
                </a>
              </span>
            </li>
            <li>
              <span class="date">7 Agosto 2008</span>
              <span class="title">
                <a href="/blog/sono-sempre-i-pi-giovani-che-se-ne-vanno-per-primi/">
                  Sono sempre i più giovani che se ne vanno per primi
                </a>
              </span>
            </li>
            <li>
              <span class="date">8 Luglio 2008</span>
              <span class="title">
                <a href="/blog/protesta-automunita/">
                  Protesta automunita
                </a>
              </span>
            </li>
            <li>
              <span class="date">1 Luglio 2008</span>
              <span class="title">
                <a href="/blog/ancora-l/">Ancora lì</a>
              </span>
            </li>
            <li>
              <span class="date">30 Giugno 2008</span>
              <span class="title">
                <a href="/blog/italian-mobile-trends-for-2011/">
                  Italian Mobile Trends for 2011
                </a>
              </span>
            </li>
            <li>
              <span class="date">19 Giugno 2008</span>
              <span class="title">
                <a href="/blog/mary-giare/">
                  Mary Giare
                </a>
              </span>
            </li>
            <li>
              <span class="date">10 Giugno 2008</span>
              <span class="title">
                <a href="/blog/snaturalizzare-il-gioco/">
                  Snaturalizzare il gioco
                </a>
              </span>
            </li>
            <li>
              <span class="date">6 Giugno 2008</span>
              <span class="title">
                <a href="/blog/fogli-di-viados/">
                  Fogli di viados
                </a>
              </span>
            </li>
            <li>
              <span class="date">4 Giugno 2008</span>
              <span class="title">
                <a href="/blog/nato-il-4-giugno/">
                  Nato il 4 giugno
                </a>
              </span>
            </li>
            <li>
              <span class="date">1 Giugno 2008</span>
              <span class="title">
                <a href="/blog/altri-due-centimetri-persi/">
                  Altri due centesimi persi
                </a>
              </span>
            </li>
            <li>
              <span class="date">30 Maggio 2008</span>
              <span class="title">
                <a href="/blog/nuovo-diverso-alternativo-strikes-again/">
                  Nuovo, diverso, alternativo strikes again
                </a>
              </span>
            </li>
            <li>
              <span class="date">14 Maggio 2008</span>
              <span class="title">
                <a href="/blog/ecomunenico/">
                  Ecomunenico
                </a>
              </span>
            </li>
            <li>
              <span class="date">7 Maggio 2008</span>
              <span class="title">
                <a href="/blog/e-ci-faccia-vedere-il-suo-ministero/">
                  E ci faccia vedere il suo ministero!!!
                </a>
              </span>
            </li>
            <li>
              <span class="date">29 Aprile 2008</span>
              <span class="title">
                <a href="/blog/les-peines-le-pen-quotidien/">
                  Les peines (Le Pen) quotidiennes
                </a>
              </span>
            </li>
            <li>
              <span class="date">28 Aprile 2008</span>
              <span class="title">
                <a href="/blog/campi-dolio/">
                  Campi d’olio
                </a>
              </span>
            </li>
            <li>
              <span class="date">15 Aprile 2008</span>
              <span class="title">
                <a href="/blog/belli-chao/">
                  Belli chao
                </a>
              </span>
            </li>
            <li>
              <span class="date">14 Aprile 2008</span>
              <span class="title">
                <a href="/blog/italia-zero/">
                  ITALIA ZERO!
                </a>
              </span>
            </li>
            <li>
              <span class="date">13 Aprile 2008</span>
              <span class="title">
                <a href="/blog/kobayashi/">Kobayashi</a>
              </span>
            </li>
            <li>
              <span class="date">11 Aprile 2008</span>
              <span class="title">
                <a href="/blog/dp-non-pd/">DP, non PD</a>
              </span>
            </li>
            <li>
              <span class="date">10 Aprile 2008</span>
              <span class="title">
                <a href="/blog/dalla-rotatoria/">
                  Dalla rotatoria
                </a>
              </span>
            </li>
            <li>
              <span class="date">4 Aprile 2008</span>
              <span class="title">
                <a href="/blog/scherzi-della-memoria-involontaria/">
                  Scherzi della memoria involontaria
                </a>
              </span>
            </li>
            <li>
              <span class="date">3 Aprile 2008</span>
              <span class="title">
                <a href="/blog/miscellanea/">
                  Miscellanea
                </a>
              </span>
            </li>
            <li>
              <span class="date">1 Aprile 2008</span>
              <span class="title">
                <a href="/blog/arcolismo/">Arcolismo</a>
              </span>
            </li>
            <li>
              <span class="date">29 Marzo 2008</span>
              <span class="title">
                <a href="/blog/perch-non-sono-qui/">
                  Perché non sono qui
                </a>
              </span>
            </li>
            <li>
              <span class="date">10 Marzo 2008</span>
              <span class="title">
                <a href="/blog/splashes-to-splashes/">
                  Splashes to splashes
                </a>
              </span>
            </li>
            <li>
              <span class="date">27 Febbraio 2008</span>
              <span class="title">
                <a href="/blog/google-robber/">
                  Google Robber
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/unioni-di-fatto/">
                  Unioni di fatto
                </a>
              </span>
            </li>
            <li>
              <span class="date">22 Febbraio 2008</span>
              <span class="title">
                <a href="/blog/exit-mix/">Exit MIX</a>
              </span>
            </li>
            <li>
              <span class="date">16 Febbraio 2008</span>
              <span class="title">
                <a href="/blog/termine-contrattuale/">
                  Termine contrattuale
                </a>
              </span>
            </li>
            <li>
              <span class="date">13 Febbraio 2008</span>
              <span class="title">
                <a href="/blog/moratori-te-salutant/">
                  Moratori te salutant
                </a>
              </span>
            </li>
            <li>
              <span class="date">10 Febbraio 2008</span>
              <span class="title">
                <a href="/blog/non-le-fate-chiedere-solo-a-me/">
                  Non le fate chiedere solo a me
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/lassurdo-non-una-fonte-rinnovabile/">
                  L’assurdo non è una fonte rinnovabile
                </a>
              </span>
            </li>
            <li>
              <span class="date">5 Febbraio 2008</span>
              <span class="title">
                <a href="/blog/vista-a-vista/">
                  Vista-a-vista
                </a>
              </span>
            </li>
            <li>
              <span class="date">30 Gennaio 2008</span>
              <span class="title">
                <a href="/blog/i-veri-falsi-in-bilancio/">
                  I veri falsi in bilancio
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/velocipedemunito/">
                  Velocipedemunito
                </a>
              </span>
            </li>
            <li>
              <span class="date">19 Gennaio 2008</span>
              <span class="title">
                <a href="/blog/cinque-anni-e-non-sentirli/">
                  Cinque anni e non sentirli
                </a>
              </span>
            </li>
            <li>
              <span class="date">16 Gennaio 2008</span>
              <span class="title">
                <a href="/blog/avanguardasigilli/">
                  Avanguardasigilli
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/lasp/">LASP</a>
              </span>
            </li>
            <li>
              <span class="date">15 Gennaio 2008</span>
              <span class="title">
                <a href="/blog/circoscrizione-obbligatoria/">
                  Circoscrizione obbligatoria
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/contro-il-metodo/">
                  Contro il metodo
                </a>
              </span>
            </li>
            <li>
              <span class="date">4 Gennaio 2008</span>
              <span class="title">
                <a href="/blog/nettuno-mi-pu-giudicare/">
                  Nettuno mi può giudicare
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/il-giorno-in-cui-nacque-luniverso/">
                  Il giorno in cui nacque l’universo
                </a>
              </span>
            </li>
            <li>
              <span class="date">2 Gennaio 2008</span>
              <span class="title">
                <a href="/blog/cult-of-cellophane/">
                  Cult of cellophane
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/cerchiobottmi/">
                  Cerchiobottèmi
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Dicembre 2007</span>
              <span class="title">
                <a href="/blog/gogol-zeitgeist/">
                  Gogol zeitgeist
                </a>
              </span>
            </li>
            <li>
              <span class="date">18 Dicembre 2007</span>
              <span class="title">
                <a href="/blog/iniziativa-speciale/">
                  Iniziativa Speciale
                </a>
              </span>
            </li>
            <li>
              <span class="date">17 Dicembre 2007</span>
              <span class="title">
                <a href="/blog/si-sta-misti/">
                  Si sta misti
                </a>
              </span>
            </li>
            <li>
              <span class="date">10 Dicembre 2007</span>
              <span class="title">
                <a href="/blog/la-sintonizzazione-a-punti/">
                  La sintonizzazione a punti
                </a>
              </span>
            </li>
            <li>
              <span class="date">9 Dicembre 2007</span>
              <span class="title">
                <a href="/blog/decameron-con-svista/">
                  Decameron con svista
                </a>
              </span>
            </li>
            <li>
              <span class="date">6 Dicembre 2007</span>
              <span class="title">
                <a href="/blog/giustizia-tre-giorni-ed-e-fatta/">
                  Giustizia, tre giorni ed è fatta
                </a>
              </span>
            </li>
            <li>
              <span class="date">5 Dicembre 2007</span>
              <span class="title">
                <a href="/blog/tempi-comuni/">
                  Tempi comuni
                </a>
              </span>
            </li>
            <li>
              <span class="date">22 Novembre 2007</span>
              <span class="title">
                <a href="/blog/prototipo-di-post-grancoalizzato/">
                  Prototipo di post grancoalizzato
                </a>
              </span>
            </li>
            <li>
              <span class="date">21 Novembre 2007</span>
              <span class="title">
                <a href="/blog/breve-storia-recente-e-prossima-dei-difensori-del-popolo/">
                  Breve storia recente e prossima dei difensori del popolo
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Novembre 2007</span>
              <span class="title">
                <a href="/blog/il-kappopolo/">
                  Il kapòpopolo
                </a>
              </span>
            </li>
            <li>
              <span class="date">18 Novembre 2007</span>
              <span class="title">
                <a href="/blog/il-teorema-del-triangolo-nero/">
                  Il teorema del triangolo nero
                </a>
              </span>
            </li>
            <li>
              <span class="date">10 Novembre 2007</span>
              <span class="title">
                <a href="/blog/skypephone-e-il-donatore-di-pollici/">
                  Skypephone e il donatore di pollici
                </a>
              </span>
            </li>
            <li>
              <span class="date">9 Novembre 2007</span>
              <span class="title">
                <a href="/blog/2-2-12000000/">
                  2 + 2 = 12.000.000
                </a>
              </span>
            </li>
            <li>
              <span class="date">30 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/naddaven-pi/">
                  Naddavenì più
                </a>
              </span>
            </li>
            <li>
              <span class="date">26 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/il-bersaglio/">
                  Il bersaglio
                </a>
              </span>
            </li>
            <li>
              <span class="date">24 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/i-curautori-nellepoca-della-loro-definibilit-tecnica/">
                  I “curautori” nell’epoca della loro definibilità tecnica
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/falsi-validi/">
                  Falsi validi
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/ateo-satanico/">
                  Ateo satanico
                </a>
              </span>
            </li>
            <li>
              <span class="date">19 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/la-particella-di-prodio/">
                  La particella di Prodio
                </a>
              </span>
            </li>
            <li>
              <span class="date">17 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/per-un-freedom-of-information-act-italiano/">
                  Per un Freedom of Information Act italiano
                </a>
              </span>
            </li>
            <li>
              <span class="date">15 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/capirla-minga/">
                  Capirla minga
                </a>
              </span>
            </li>
            <li>
              <span class="date">14 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/bindigestione/">
                  Bindigestione
                </a>
              </span>
            </li>
            <li>
              <span class="date">12 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/il-mare-dautunno/">
                  Il mare d’autunno
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/la-nuova-canzone/">
                  La nuova canzone
                </a>
              </span>
            </li>
            <li>
              <span class="date">8 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/venture-nella-capitale/">
                  Venture nella Capitale
                </a>
              </span>
            </li>
            <li>
              <span class="date">4 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/uno-skel-per-free-burma/">
                  Uno Skel per Free Burma
                </a>
              </span>
            </li>
            <li>
              <span class="date">3 Ottobre 2007</span>
              <span class="title">
                <a href="/blog/lariofaga/">Lariofagìa</a>
              </span>
            </li>
            <li>
              <span class="date">30 Settembre 2007</span>
              <span class="title">
                <a href="/blog/i-tagli-bassi-del-giornale/">
                  I tagli bassi del Giornale
                </a>
              </span>
            </li>
            <li>
              <span class="date">28 Settembre 2007</span>
              <span class="title">
                <a href="/blog/nellocchio-della-classifica/">
                  Nell’occhio della classifica
                </a>
              </span>
            </li>
            <li>
              <span class="date">23 Settembre 2007</span>
              <span class="title">
                <a href="/blog/la-mappa-delle-forme-errate/">
                  La mappa delle forme errate
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Settembre 2007</span>
              <span class="title">
                <a href="/blog/pallacordacamp/">
                  PallacordaCamp
                </a>
              </span>
            </li>
            <li>
              <span class="date">15 Settembre 2007</span>
              <span class="title">
                <a href="/blog/er-popolo-romano-chiede/">
                  Er popolo romano chiede
                </a>
              </span>
            </li>
            <li>
              <span class="date">13 Settembre 2007</span>
              <span class="title">
                <a href="/blog/corazze/">Corazze</a>
              </span>
            </li>
            <li>
              <span class="date">12 Settembre 2007</span>
              <span class="title">
                <a href="/blog/migliorismo-grillino/">
                  Migliorismo grillino
                </a>
              </span>
            </li>
            <li>
              <span class="date">11 Settembre 2007</span>
              <span class="title">
                <a href="/blog/stream-of-consciousness-110901/">
                  Stream of consciousness, 11/09/01
                </a>
              </span>
            </li>
            <li>
              <span class="date">7 Settembre 2007</span>
              <span class="title">
                <a href="/blog/indennizzi-mostruosamente-proibiti/">
                  Indennizzi mostruosamente proibiti
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/un-giocattolo-in-meno-oggi-per-un-lavoro-in-pi-domani/">
                  Un giocattolo in meno oggi per un lavoro in più domani
                </a>
              </span>
            </li>
            <li>
              <span class="date">3 Settembre 2007</span>
              <span class="title">
                <a href="/blog/pseudonimia-onomastico-omofona/">
                  Pseudonimia onomastico-omofona
                </a>
              </span>
            </li>
            <li>
              <span class="date">28 Agosto 2007</span>
              <span class="title">
                <a href="/blog/estatistiche/">
                  Estatistiche
                </a>
              </span>
            </li>
            <li>
              <span class="date">3 Agosto 2007</span>
              <span class="title">
                <a href="/blog/la-vuelta-buena/">
                  La vuelta buena
                </a>
              </span>
            </li>
            <li>
              <span class="date">2 Agosto 2007</span>
              <span class="title">
                <a href="/blog/its-a-little-bit-funny/">
                  It’s a little bit funny
                </a>
              </span>
            </li>
            <li>
              <span class="date">29 Luglio 2007</span>
              <span class="title">
                <a href="/blog/tre-vuoti-imprevisti-e-mezzo/">
                  Tre vuoti imprevisti e mezzo
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/scatola-vota/">
                  Scatola vota
                </a>
              </span>
            </li>
            <li>
              <span class="date">17 Luglio 2007</span>
              <span class="title">
                <a href="/blog/sogni-di-fuga-radioattiva/">
                  Sogni di fuga (radioattiva)
                </a>
              </span>
            </li>
            <li>
              <span class="date">16 Luglio 2007</span>
              <span class="title">
                <a href="/blog/lapsus-di-esistenza/">
                  Lapsus di esistenza
                </a>
              </span>
            </li>
            <li>
              <span class="date">13 Luglio 2007</span>
              <span class="title">
                <a href="/blog/convivenza-autorizzata-dalla-chiesa-cattolica/">
                  Convivenza Autorizzata dalla Chiesa Cattolica
                </a>
              </span>
            </li>
            <li>
              <span class="date">9 Luglio 2007</span>
              <span class="title">
                <a href="/blog/lordine-dei-nottalisti/">
                  L’ordine dei nottalisti
                </a>
              </span>
            </li>
            <li>
              <span class="date">6 Luglio 2007</span>
              <span class="title">
                <a href="/blog/la-recensione-afona/">
                  La recensione aFona
                </a>
              </span>
            </li>
            <li>
              <span class="date">2 Luglio 2007</span>
              <span class="title">
                <a href="/blog/preokkupati/">
                  Preokkupati
                </a>
              </span>
            </li>
            <li>
              <span class="date">28 Giugno 2007</span>
              <span class="title">
                <a href="/blog/sintesi-veltroniana-una-lettura-oulipiana-di-sinistra/">
                  Sintesi veltroniana: una lettura oulipiana di sinistra
                </a>
              </span>
            </li>
            <li>
              <span class="date">27 Giugno 2007</span>
              <span class="title">
                <a href="/blog/uno-imille-e-i-centomila/">
                  Uno, iMille e i centomila
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/icinquecento-e-gli-altri/">
                  iCinquecento e gli altri
                </a>
              </span>
            </li>
            <li>
              <span class="date">22 Giugno 2007</span>
              <span class="title">
                <a href="/blog/il-blog-prenatale-non-pi-pre/">
                  Il blog prenatale non è più pre
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/lantispam-del-cavaliere/">
                  L’antispam del Cavaliere
                </a>
              </span>
            </li>
            <li>
              <span class="date">21 Giugno 2007</span>
              <span class="title">
                <a href="/blog/non-correre-papa/">
                  Non correre, Papa
                </a>
              </span>
            </li>
            <li>
              <span class="date">16 Giugno 2007</span>
              <span class="title">
                <a href="/blog/radiohead-gli-avanzi-del-giorno-dopo/">
                  Radiohead, gli avanzi del giorno dopo
                </a>
              </span>
            </li>
            <li>
              <span class="date">14 Giugno 2007</span>
              <span class="title">
                <a href="/blog/la-vistona/">
                  La Vistona
                </a>
              </span>
            </li>
            <li>
              <span class="date">13 Giugno 2007</span>
              <span class="title">
                <a href="/blog/che-intercettazioni-vorresti-leggere/">
                  Che intercettazioni vorresti leggere?
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/come-spesso-capita-ai-giovani/">
                  Come spesso capita ai giovani
                </a>
              </span>
            </li>
            <li>
              <span class="date">11 Giugno 2007</span>
              <span class="title">
                <a href="/blog/furbo-out/">
                  Furbo è out
                </a>
              </span>
            </li>
            <li>
              <span class="date">31 Maggio 2007</span>
              <span class="title">
                <a href="/blog/la-neve-a-roma/">
                  La neve a Roma
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/le-superfici-basse/">
                  Le superfici basse
                </a>
              </span>
            </li>
            <li>
              <span class="date">30 Maggio 2007</span>
              <span class="title">
                <a href="/blog/la-kratochvilova-ancora-viva/">
                  La Kratochvilova è ancora viva
                </a>
              </span>
            </li>
            <li>
              <span class="date">28 Maggio 2007</span>
              <span class="title">
                <a href="/blog/pingare-il-partito-democratico/">
                  Pingare il Partito Democratico
                </a>
              </span>
            </li>
            <li>
              <span class="date">27 Maggio 2007</span>
              <span class="title">
                <a href="/blog/esorcismo-contro-le-canzoni-revenant/">
                  Esorcismo contro le canzoni revenant
                </a>
              </span>
            </li>
            <li>
              <span class="date">25 Maggio 2007</span>
              <span class="title">
                <a href="/blog/le-primarie-per-scegliersi-lavversario/">
                  Le primarie per scegliersi l’avversario
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Maggio 2007</span>
              <span class="title">
                <a href="/blog/video-zena-quante-bello/">
                  Video Zena quant’è bello
                </a>
              </span>
            </li>
            <li>
              <span class="date">16 Maggio 2007</span>
              <span class="title">
                <a href="/blog/darwinismo-canzonettistico/">
                  Darwinismo canzonettistico
                </a>
              </span>
            </li>
            <li>
              <span class="date">14 Maggio 2007</span>
              <span class="title">
                <a href="/blog/cast-hommes/">
                  Cast Hommes
                </a>
              </span>
            </li>
            <li>
              <span class="date">11 Maggio 2007</span>
              <span class="title">
                <a href="/blog/meno-famiglia-grazie/">
                  Meno famiglia, grazie
                </a>
              </span>
            </li>
            <li>
              <span class="date">7 Maggio 2007</span>
              <span class="title">
                <a href="/blog/chanson-segocentrique/">
                  Chanson Ségocentrique
                </a>
              </span>
            </li>
            <li>
              <span class="date">2 Maggio 2007</span>
              <span class="title">
                <a href="/blog/chi-non-parla-con-me-pesto-lo-colga/">
                  Chi non parla con me, pesto lo colga!
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/comunismo-reale/">
                  Comunismo reale
                </a>
              </span>
            </li>
            <li>
              <span class="date">26 Aprile 2007</span>
              <span class="title">
                <a href="/blog/perche-uso-twitter-gia-perche/">
                  Perché uso Twitter? Già, perché?
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Aprile 2007</span>
              <span class="title">
                <a href="/blog/limbo-kid/">Limbo Kid</a>
              </span>
            </li>
            <li>
              <span class="date">18 Aprile 2007</span>
              <span class="title">
                <a href="/blog/eugenio-finardi/">
                  L’HTML fa dimagrire
                </a>
              </span>
            </li>
            <li>
              <span class="date">17 Aprile 2007</span>
              <span class="title">
                <a href="/blog/shot-happens/">
                  Shot happens
                </a>
              </span>
            </li>
            <li>
              <span class="date">15 Aprile 2007</span>
              <span class="title">
                <a href="/blog/regole-a-bassa-asseverativita/">
                  Regole a bassa asseveratività
                </a>
              </span>
            </li>
            <li>
              <span class="date">5 Aprile 2007</span>
              <span class="title">
                <a href="/blog/e-adesso-sfogliati/">
                  E adesso sfogliati!
                </a>
              </span>
            </li>
            <li>
              <span class="date">3 Aprile 2007</span>
              <span class="title">
                <a href="/blog/ritaliacamp-tra-il-dire-e-il-fare/">
                  RitaliaCamp, tra il dire e il fare…
                </a>
              </span>
            </li>
            <li>
              <span class="date">28 Marzo 2007</span>
              <span class="title">
                <a href="/blog/ibm-sponsorizza-ritalia/">
                  IBM sponsorizza Ritalia
                </a>
              </span>
            </li>
            <li>
              <span class="date">27 Marzo 2007</span>
              <span class="title">
                <a href="/blog/too-passionate/">
                  Too Passionate!
                </a>
              </span>
            </li>
            <li>
              <span class="date">25 Marzo 2007</span>
              <span class="title">
                <a href="/blog/europedia/">Europèdia</a>
              </span>
            </li>
            <li>
              <span class="date">21 Marzo 2007</span>
              <span class="title">
                <a href="/blog/google-self-bombing/">
                  Google self-bombing
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Marzo 2007</span>
              <span class="title">
                <a href="/blog/collezioni-di-sabbia-piu-fine/">
                  Collezioni di sabbia più fine
                </a>
              </span>
            </li>
            <li>
              <span class="date">17 Marzo 2007</span>
              <span class="title">
                <a href="/blog/56-milioni-di-tallonatori/">
                  56 milioni di tallonatori
                </a>
              </span>
            </li>
            <li>
              <span class="date">15 Marzo 2007</span>
              <span class="title">
                <a href="/blog/il-nono-rischio/">
                  Il nono rischio
                </a>
              </span>
            </li>
            <li>
              <span class="date">9 Marzo 2007</span>
              <span class="title">
                <a href="/blog/solo-cancellare-ci-salvera/">
                  Solo cancellare ci salverà?
                </a>
              </span>
            </li>
            <li>
              <span class="date">28 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/openid-minuto-per-minuto/">
                  OpenId minuto per minuto
                </a>
              </span>
            </li>
            <li>
              <span class="date">27 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/ritalioti-tra-cui-me/">
                  RItalioti, tra cui me
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/in-poche-ore/">
                  In poche ore
                </a>
              </span>
            </li>
            <li>
              <span class="date">23 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/italgaffe/">Italgaffe</a>
              </span>
            </li>
            <li>
              <span class="date">22 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/e-se-ai-cinesi-piacesse/">
                  E se ai cinesi piacesse?
                </a>
              </span>
            </li>
            <li>
              <span class="date">21 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/il-nordest-e-la-stanghetta-ditalia/">
                  Il nordest è la stanghetta d’Italia
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/webcards-e-operator-i-microformat-sono-tra-noi/">
                  Webcards e Operator: i Microformat sono tra noi
                </a>
              </span>
            </li>
            <li>
              <span class="date">20 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/sugli-alluci-dei-giganti/">
                  Sugli alluci dei giganti
                </a>
              </span>
            </li>
            <li>
              <span class="date">14 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/le-spammeur/">
                  Le spamMeur
                </a>
              </span>
            </li>
            <li>
              <span class="date">5 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/turanisauro/">
                  Turanisauro
                </a>
              </span>
            </li>
            <li>
              <span class="date">2 Febbraio 2007</span>
              <span class="title">
                <a href="/blog/cercasi-62-mila-cittadini-digitali/">
                  Cercasi 62 mila Cittadini Digitali
                </a>
              </span>
            </li>
            <li>
              <span class="date">25 Gennaio 2007</span>
              <span class="title">
                <a href="/blog/its-the-barcamp-stupid/">
                  It’s the BarCamp, stupid!
                </a>
              </span>
            </li>
            <li>
              <span class="date">18 Gennaio 2007</span>
              <span class="title">
                <a href="/blog/in-assenza-di-definizione/">
                  In assenza di definizione
                </a>
              </span>
            </li>
            <li>
              <span class="date">4 Gennaio 2007</span>
              <span class="title">
                <a href="/blog/pervasive-sharing/">
                  Pervasive Sharing
                </a>
              </span>
            </li>
            <li>
              <span class="date">29 Dicembre 2006</span>
              <span class="title">
                <a href="/blog/a-cavallino-donato/">
                  A cavallino donato…
                </a>
              </span>
            </li>
            <li>
              <span class="date">17 Dicembre 2006</span>
              <span class="title">
                <a href="/blog/la-setta-dei-post-estinti/">
                  La setta dei Post estinti
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/persona-dellanno-posso-rifiutare/">
                  Persona dell’anno. Posso rifiutare?
                </a>
              </span>
            </li>
            <li>
              <span class="date">15 Dicembre 2006</span>
              <span class="title">
                <a href="/blog/leweb-3-chi-tocca-la-massa-muore/">
                  Leweb 3: chi tocca la massa muore
                </a>
              </span>
            </li>
            <li>
              <span class="date">5 Dicembre 2006</span>
              <span class="title">
                <a href="/blog/feedburner-ad-network-puo-funzionare/">
                  Feedburner AD Network, può funzionare?
                </a>
              </span>
            </li>
            <li>
              <span class="date"></span>
              <span class="title">
                <a href="/blog/sono-finito-tra-i-blogger/">
                  Sono finito tra i blogger!
                </a>
              </span>
            </li>
          </ul>
        </content>
      </Layout>
    </>
  )
}
